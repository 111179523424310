.tour-calendar .rbc-time-view {
	border-radius: 10px;
}

.tour-calendar .rbc-today {
	background-color: white;
}

.tour-calendar .rbc-header {
	padding: 10px 20px 18px 20px;
	background-color: #F8F8F8;
	color: #333333;
	font-size: var(--font-size-16);
	font-weight: 500;
}

.tour-calendar .rbc-toolbar-label {
	font-weight: bold;
}

.tour-week-view .rbc-timeslot-group {
	min-height: 120px;
	height: 100%;
}


.tour-day-view .rbc-timeslot-group {
	min-height: 120px;
	height: 100%;
}

.tour-calendar .rbc-label {
	margin-top: 10px;
}

.tour-calendar .rbc-current-time-indicator {
	display: none;
}

.tour-calendar .rbc-event {
	font-size: var(--font-size-14);
	font-weight: 500;
}

.tour-calendar .rbc-toolbar {
	font-weight: 500;
	color: #5B5B5B
}

.tour-calendar .rbc-toolbar> :first-child> :first-child {
	display: none;
}

.tour-calendar .rbc-toolbar> :first-child> :nth-child(2) {
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

.tour-calendar .rbc-agenda-view td {
	background-color: white;
	color: #333333;
	border: 0.5px solid #D0D0D0;
	padding: 5px;
}

.tour-calendar .rbc-off-range-bg {
	background-color: white;
}

.tour-calendar .rbc-event-label {
	font-weight: bold;
}

.tour-calendar .rbc-btn-group .rbc-active {
	background-color: rgb(0, 170, 204, 0.5) !important;
	box-shadow: none !important;
}

.tour-calendar .rbc-btn-group button {
	border: none;
}

.tour-calendar .rbc-toolbar> :nth-child(3) {
	display: none;
}

.tour-calendar .rbc-show-more {
	font-size: var(--font-size-13) !important;
}

.tour-calendar .rbc-event-label {
	font-weight: bold;
}

.tour-calendar .rbc-btn-group .rbc-active {
	background-color: rgb(0, 170, 204, 0.5) !important;
	box-shadow: none !important;
}

.tour-calendar .rbc-btn-group button {
	border: none;
}

.tour-calendar .rbc-toolbar> :nth-child(3) {
	display: none;
}

.tour-calendar .rbc-show-more {
	font-size: var(--font-size-13) !important;
}

.tour-calendar .rbc-month-row {
	overflow: visible;
}

.tour-calendar .rbc-row-segment {
	padding-left: 4px;
	padding-right: 4px;
	margin-bottom: 3px;
}

.tour-rtl .rbc-btn-group {
	direction: ltr;
}

.tour-calendar .rbc-allday-cell {
	display: none;
}

.tour-calendar .rbc-time-content {
	border-top: none;
}

.tour-management-main-div .tour-list-card:first-child {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
}

.tour-management-main-div .tour-list-card:last-child {
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	border-bottom: none !important;
}

.tour-listview-header {
	display: flex;
	justify-content: space-between;
	padding-left: 20px !important;
	padding-right: 20px !important;
	color: #333333;
	font-weight: 600;
	font-size: var(--font-size-18);
	align-items: center;
	min-height: 60px;
	border-bottom: 0.5px solid #D0D0D0;
	background-color: white;
}

.student-calendar-inline {
	display: flex;
	gap: 6px;
	align-items: center;
	color: #5B5B5B;
	font-size: var(--font-size-15);
	font-weight: 500;
	margin-top: 12px;
}

.rbc-events-container {
	width: fit-content;
}

.rbc-calendar {
	min-width: 1250px;
	overflow-x: scroll;
}

.tour-calendar {
	overflow: scroll;
}

.rbc-event {
	width: fit-content;
	white-space: nowrap;
	word-break: break-word;

}

.rbc-day-slot .rbc-event {
	width: fit-content !important;
	white-space: nowrap;
	word-break: break-word;
}

.rbc-day-slot {
	overflow-x: hidden;
}

.rbc-day-slot .rbc-event-label {
	display: none;
}