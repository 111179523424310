.calendar-infoIconStyle {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  cursor: pointer;
  margin-bottom: -3px;
}

.calendar-packName {
  color: #333;
  font-size: var(--font-size-18);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.schedule-management-card {
  background-color: transparent !important;
  border: none !important;
  box-shadow: 0px 4px 25px 0px #0000000F !important;
  border-radius: 5px !important;
}

.schedule-management-card .ant-collapse-header {
  padding: 22px 16px !important;
}

.displayFlexSpaceBetween {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.displayFlex {
  display: flex;
  align-items: center;
}

.schedule-table th {
  font-size: var(--font-size-14) !important;
  font-weight: 700 !important;
  color: #333333 !important;
  padding: 11px 16px !important;
}

.calendarOptions {
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #F8F8F8;
  cursor: pointer;
  margin-left: 10px;
}

.calendar-popOptions .ant-list-item {
  justify-content: flex-start !important;
  cursor: pointer;
  border: none !important;
  padding: 10px 5px !important;
  font-size: var(--font-size-14) !important;
  font-weight: 400;
  align-items: center !important;
}

.calendar-popOption-image {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.drawer-activity-name {
  font-size: var(--font-size-16);
  font-weight: 600;
}

.mt-16 {
  margin-top: 16px;
}

.drawer-schedule-detail {
  font-size: var(--font-size-15);
  font-weight: 500;
  color: #5b5b5b;
}

.drawer-heading {
  margin-top: 26px;
  font-size: var(--font-size-15);
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #D0D0D0;
  margin-bottom: 10px;
}

.gap-25 {
  gap: 25px;
}

.displayFlexSpaceBetweenNoWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.drawer-student-profile {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.std-sch-bb {
  padding-bottom: 10px;
  border-bottom: 0.5px solid #D0D0D0;
}

.package-legend {
  height: 25px;
  width: 25px;
  border-radius: 8px;
  margin-right: 12px;
}

.legend-displayflex-alignItems {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.legend-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}


.custom-month-picker .ant-picker-month-panel {
  position: static !important;
  transform: none !important;
  display: block !important;
  box-shadow: none !important;

}

.custom-week-picker .ant-picker-week-panel {
  position: static !important;
  transform: none !important;
  display: block !important;
  box-shadow: none !important;
}

.custom-month-picker .ant-picker-panel-container {
  box-shadow: none !important;
  background-color: #F5F7FB !important;
}

.custom-week-picker .ant-picker-panel-container {
  box-shadow: none !important;
  background-color: #F5F7FB !important;
}

.custom-month-picker .ant-picker-input {
  display: none !important;
  border: none !important;
}

.custom-week-picker .ant-picker-input {
  display: none !important;
  border: none !important;
}

.custom-day-picker {
  /* width: 100%; */
  margin-top: 20px;
}

.custom-week-picker {
  /* width: 100%; */
  position: relative;
}

.custom-month-picker {
  /* width: 60%; */
  position: relative;

}

.custom-week-picker .ant-picker-dropdown {
  position: static !important;
  transform: none !important;
  /* width: 100% !important; */
  display: block !important;
  justify-content: center !important;

}

.custom-month-picker .ant-picker-dropdown {
  position: static !important;
  transform: none !important;
  /* width: 60% !important; */
  display: block !important;
  justify-content: center !important;

}

.custom-month-picker .ant-picker-outlined {
  border: none;
  background-color: #F5F7FB;
}

.custom-week-picker .ant-picker-outlined {
  border: none;
  background-color: #F5F7FB;
}

.labelstyle {
  color: #4B4F52;
  font-size: var(--font-size-16);
}

.custom-day-picker .rdp-selected .rdp-day_button {
  background-color: var(--primary-color);
  border-radius: 20px;
  color: white;
  border: none !important
}

.rbc-events-container {
  position: static !important;
}

/* Mobile view of calendar and responsiveness */

@media only screen and (max-width: 940px) {

  .mobile-calendar-view {
    display: block !important;
  }

  .laptop-calendar-view {
    display: none !important;
  }

  /* Hinding single schedule view drawer in mobile-view */
  .ant-drawer:has(.laptop-view-single-schedule-drawer) {
    display: none !important;
  }
}

.mobile-calendar-view {
  display: none;
}

.laptop-calendar-view {
  display: block;
}

.mobile-calendar-filter-row {
  margin-bottom: 22;
  display: flex;
  gap: 10;
  justify-content: space-between;
  align-items: center;
}

.mobile-calendar-search {
  width: calc(100% - 95px);
}



.cl-nav-buttons {
  display: flex;
  align-items: center;
}

/* .fc-popover-body .fc-event {
  background: #f8f9fa !important;
  border-left: 5px solid #007bff !important;
  padding: 5px !important;
  margin-bottom: 5px !important;
  border-radius: 5px !important;
} */
/* .fc-event {
  background: #f8f9fa !important;
  border-left: 5px solid #007bff !important;
  padding: 5px !important;
  margin-bottom: 5px !important;
  border-radius: 5px !important;
} */

/* .fc-popover-body .fc-event .cl-custom-event {
  padding: 1px !important;
  border: none !important;
  display: flex !important;
  justify-content: space-between !important;
} */

.fc-popover-body {
  padding: 10px !important;
  max-height: 600px !important;
  /* Set max height */
  overflow-y: auto !important;
  /* Enable vertical scroll */
}

.event-title {
  white-space: wrap;
  word-break: break-word;
}

.fc-event-time {
  margin-bottom: 1px;
  overflow: auto !important;
}

.custom-fc a {
  color: #969696 !important;
}

.custom-fc .fc-daygrid-more-link {
  color: #000000 !important;
  font-weight: 500;
}

.custom-month-picker .ant-picker-month-panel {
  width: auto !important;
}

.custom-week-picker .ant-picker-week-panel {
  width: auto !important;

}


.custom-day-picker .rdp-day {
  @media screen and (max-width: 1400px) {
    height: 40px !important;
    width: 38px !important;
  }
}

.custom-day-picker .rdp-day_button {
  @media screen and (max-width: 1400px) {
    height: 40px !important;
    width: 38px !important;
  }
}

.fc .fc-timegrid-slot-minor {
  border-top: none !important;
}

.fc-timeGridDay-view {
  --fc-today-bg-color: transparent !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}

.fc .fc-view-harness {
  height: calc(100vh - 200px) !important;
}

.fc-header-date-today {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: #FFFFFF;
  /* padding: 8px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-weight: 400;
}

.fc-header-date {
  color: #003861;
  /* padding: 8px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-weight: 400;
}

.fc-header-day {
  color: #969696;
  font-size: var(--font-size-14);
  font-weight: 400;
  text-transform: uppercase !important;
}

.fc-col-header-cell {
  height: 60px !important;
  /* Adjust height as needed */
  padding: 10px 0 !important;
  /* Adds spacing inside the cell */
}

.fc-col-header-cell-cushion {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  height: 100%;
}

.fc-top-header {
  background-color: #FFFFFF;
  border: 1px solid #E8E8E8;
}

.pt-20 {
  padding-top: 20px;
}

.custom-fc {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
}

.fc-theme-standard .fc-scrollgrid {
  border-top: 1px solid #E8E8E8
}

/* .fc-theme-standard th {
  border-right: 0px;
} */

.fc-timegrid-slot {
  height: 40px !important;
}

.fc-timegrid-slot-label-cushion {
  color: #969696 !important;
  text-transform: uppercase !important;
  font-size: var(--font-size-14) !important;
}

.fc-search input::placeholder {
  color: #5B5B5B !important
}



.fc .fc-daygrid-day-top {
  flex-direction: row !important;
}

.fc-col-header .fc-day-today {
  background-color: #E9EAFE !important;
}

.fc-day-today .fc-daygrid-day-number {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: #FFFFFF !important;
  /* padding: 8px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px
}

.fc-popover {
  visibility: hidden;
}

.fc-popup-day {
  color: #969696;
  font-weight: 600;
  text-transform: uppercase;
  font-size: var(--font-size-16);
}

.fc-popup-date {
  font-weight: 600;
  font-size: var(--font-size-16);
}

.event-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-shrink: 0;
}

.fc-popup-displayFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 14px;
}