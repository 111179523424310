.btnContainer {
    display: inline-flex;
    border: 1px solid #D0D0D0;
    padding: 6px 12px;
    border-radius: 8px;
    height: 33px;
}

.imgContainer {
    height: var(--font-size-18);
    width: var(--font-size-18);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
}

.mobFilterImg {
    height: var(--font-size-18);
    width: var(--font-size-18);
}

.verticalDivider {
    height: 19px;
    display: inline-block;
    width: 1px;
    border-radius: 50%;
    background-color: #D0D0D0;
    margin: 0px 15px;
}

.appliedFilterCount {
    display: inline-flex;
    align-items: center;
}