.coupon {
  width: calc(-webkit-fill-available - 20px);
  padding: 20px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px #00000012;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  margin-top: 25px;

  /* height: 111px; */

  @media screen and (max-width: 992px) {
    position: relative !important;
  }
}

.coupon::before,
.coupon::after {
  content: '';
  position: absolute;
  width: 34px;
  height: 34px;
  /* background: #9b9b9b17; */
  background: #92929217;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.coupon::before {
  left: -17px;
}

.coupon::after {
  right: -17px;
}

.coupon .content {
  padding: 0px 15px;
}

.coupon h2 {
  margin: 0;
  color: #d9534f;
  font-size: 24px;
}

.coupon p {
  margin: 10px 0;
  color: #666;
  font-size: 16px;
}

.book-package-name {
  font-size: var(--font-size-16);
  font-family: "mulish";
  font-weight: 700;
}

.book-pkg-card {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 12px 20px;
}

.mobile-bottom-sheet-summary {
  border-radius: 16px;
  word-wrap: break-word;
  word-break: break-all;
  margin: 30px 0px 0px 0px;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 992px) {
    margin: 20px 0px 0px 0px;
  }

  @media screen and (max-width: 576px) {
    margin: 15px 0px 0px 0px;
  }
}

.book-main-card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 3.31px 20.69px 0px #00000012;
  border: 0.17px solid #E0E0E0;
  word-wrap: break-word;
  word-break: break-all;
  margin: 30px 0px 0px 0px;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: 992px) {
    margin: 20px 0px 0px 0px;
  }

  @media screen and (max-width: 576px) {
    margin: 15px 0px 0px 0px;
  }
}

.book-head-mt {
  margin-top: 0px;

  @media screen and (max-width: 576px) {
    margin-top: 10px
  }
}

.mobile-coupon-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}


@media only screen and (min-width: 671px) {
  .mobile-bottom-fill {
    height: 25px;
    width: 100%;
  }

  .book-main-row .laptop-book-summary {
    display: block !important;
  }

  .mobile-view-b-fix-pay {
    display: none !important;
  }
}


@media only screen and (max-width: 670px) {

  .laptop-book-summary {
    display: none !important;
  }

  .mobile-bottom-fill {
    height: 125px;
    width: 100%;
  }

  .mobile-view-b-fix-pay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.17) 0px 4px 30px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
    border-radius: 8px 8px 0px 0px;
    z-index: 1000;
    background-color: white;
  }
}

.book-summary-mobile-arrow {
  height: 18px;
  width: 18px;
  margin: 3px 0px 0px 10px;
}

.package-pricing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-left: 5px;
}

.proceed-mobile-bottom {
  display: flex;
  width: 100%;
}

.book-summary {
  margin-top: 60;
  margin-bottom: 24;

  @media screen and (max-width: 1300px) {
    margin: 40px 0px 0px 0px;
  }

  @media screen and (max-width: 992px) {
    margin: 25px 0px 0px 0px;
  }

  @media screen and (max-width: 576px) {
    margin: 15px 0px 0px 0px;
  }
}

.book-card-padding {
  padding: 18px;
}

.book-package-date {
  font-size: var(--font-size-15);
  color: #5B5B5B;
  margin-top: 8px;
  font-weight: 400;
}

.book-pricepack-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  overflow-x: auto;
  background: transparent;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scroll-behavior: smooth;
  padding: 8px 0px 5px 8px;
}

.book-pricepack-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4.02px 8.03px 0px #7413BD12;
  border: 0.4px solid #C6C6C6;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7413BD;
  min-width: 220px;
  cursor: pointer;
  gap: 5px;
  flex: 0 0 fit-content;
}

.book-header-margin-bottom {
  margin-bottom: 11px;
}

.book-pricepack-amt {
  font-size: var(--font-size-24);
  font-weight: 800;
  white-space: pre-wrap;
}

.book-alignItem-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.book-margin-left {
  margin-left: 20px;
}

.book-login-div {
  background-color: #F4F6FD;
  padding: 4px 10px 4px 20px;
  font-size: var(--font-size-15);
  color: #5B5B5B;
  min-height: 38px;
  line-height: 30px;
}

.book-contact-headers {
  border-bottom: 0.17px solid #D0D0D0;
  padding: 12px 20px;
  font-weight: 500;
}

.book-padding-12-20 {
  padding: 12px 20px;
}

.book-participant-container {
  padding: 12px 16px;
}

.book-field-label {
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-bottom: 8px;
}

.book-margin-bottom-12 {
  margin-bottom: 12px;
}

.book-add-participant {
  display: flex;
  padding: 14px 20px;
  color: #7413BD;
  align-items: center;
  border-top: 0.17px solid #D0D0D0;
  font-weight: 600;
}

.book-add-participant-btn {
  cursor: pointer;
  margin-left: 6px;
  font-size: var(--font-size-16);
}

.book-fontWeight600 {
  font-weight: 600;
}

.book-text-color {
  color: #7413BD
}

.book-fontWeightlight {
  font-weight: 300;
  font-size: var(--font-size-15);
  line-height: 25px;
}

.calendar-weeks {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 16px;
}

.calendar-weeks div {
  cursor: pointer;
  /* height: 44px; */
  vertical-align: middle;
  font-size: var(--font-size-14);
  line-height: 44px;
  color: var(--primary-color);
  padding: 8px 0px;

  @media screen and (max-width: 450px) {
    padding: 6px 0px;
  }
}

.calendar-weeks .selected p {
  background-color: var(--primary-color) !important;
  color: white !important;
  border-radius: 3px;
}

.calendar-weeks p {
  padding: 0px 6px;
  height: 28px;
  line-height: 28px;
}

.rdp-weekday {
  color: var(--primary-color);
  font-weight: 600 !important;
}

.rdp-selected {
  font-weight: inherit !important;
}

.b-slots-container {
  border: 0.28px solid #0000004D;
  border-radius: 19.5px;
  width: fit-content;
}

.slots {
  padding: 20px;
  display: flex;
  align-items: flex-end;
  min-width: 353px;
  font-size: var(--font-size-16);

  @media screen and (max-width: 450px) {
    font-size: var(--font-size-14);
    padding: 10px;
    min-width: 250px;
  }
}

.slots .rdp-selected {
  font-size: var(--font-size-16) !important;
}

.slots .rdp-day {
  @media screen and (max-width: 450px) {
    height: 40px !important;
    width: 38px !important;
  }
}

.day-picker-div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #E5E7EB;
  padding: 20px 25px 15px 20px;
}

.slots-weekdays {
  border-radius: 10px;
  border: 1px solid #ECECEC;
  gap: 11px;
  padding: 7px 13px;
}

.slots-weekdays div {
  width: 40px;
  font-size: var(--font-size-13);
  border: 1px solid var(--primary-color);
  color: #5b5b5b;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.slots-weekdays .selected {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.summary-collapse .ant-collapse-header {
  padding: 0 !important;
}

.summary-collapse .ant-collapse-content-box {
  padding: 0 !important;
  padding-block: 0 !important;
}

.slots-timings {
  gap: 8px;
  margin-top: 6px;
  flex-wrap: wrap;
}

.slots-timings div {
  padding: 0px 11px;
  font-size: var(--font-size-14);
  font-weight: 400;
  border: 0.43px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 6px;
  text-align: center;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
}

.slots-timings .selected {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.rdp-selected .rdp-day_button {
  border: none !important;
}

.rdp-selected {
  background-color: var(--rdp-accent-background-color);
}

.timing-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.book-weekdays {
  text-transform: capitalize;
}

.month-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.base-price {
  font-size: var(--font-size-16);
  font-weight: 300;
}

.base-price-desc {
  color: #5b5b5b;
  font-weight: 300 !important;
  font-size: var(--font-size-15) !important;
}

.clear-dates {
  display: flex;
  justify-content: flex-end;
  padding: 0px 35px 10px 0px;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  cursor: pointer;
}

.book-total {
  font-size: var(--font-size-16);
  color: #1A1A1A;
  font-weight: 400;
}

.book-schedule-container {
  display: flex;
  gap: 12px;

  @media screen and (max-width: 1220px) {
    flex-direction: column !important;
    gap: 24px;

  }
}

.book-calendar-container {
  min-width: 50%;
  width: fit-content;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1220px) {
    width: 100%;
    padding-right: 0;
  }
}

.book-calendar-border {
  border-right: 1px solid #0000001A;

  @media screen and (max-width: 1220px) {
    border: none !important;
  }
}

.book-p-39 {
  padding: 39px;

  @media screen and (max-width: 480px) {
    padding: 14px;
  }
}

.book-timing-container {
  padding-left: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  @media screen and (max-width: 1220px) {
    padding-left: 0px;
  }
}

.book-collapse-children {
  margin-left: 28px;
  margin-top: 5px;
  text-transform: capitalize;
}

.book-collapse-children-2 {
  margin-left: 33px;
  margin-top: 5px;
  text-transform: capitalize;
}

.base-price-desc-2 {
  color: #5b5b5b;
  font-weight: 300 !important;
  font-size: var(--font-size-14) !important;
}

.book-main-row {
  width: 90%;
  margin: 0 auto;

  @media screen and (max-width: 1368px) {
    width: 100%;
  }

}

.book-green {
  color: #10B981 !important;
}

.book-green .ant-collapse-expand-icon {
  color: #10B981 !important;
}

.b-border-top {
  border-top: 0.5px solid #D0D0D0;
}

.horizontal-book-divider {
  width: 100%;
  margin: 10px 0px 15px 0px;
  border-radius: 8px;
  height: 1px;
  border: 0.5px solid #EAEAEA;
}

.book-font-w-600 {
  font-weight: 600;
}

.book-normal-font-size {
  font-size: 15px;
}

.admin-book-participants-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.book-participants-plus-icon {
  height: 20px;
  width: 20px;
}

.book-participants-plus-container {
  height: 100%;
  width: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.book-participant-tags-cont {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.book-participant-tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #EEEEEE;
  gap: 10px;
  padding: 5px 15px;
  border-radius: 25px;
  cursor: pointer;
}

.book-participant-tag-checkbox .ant-checkbox-input {
  height: 15px !important;
  width: 15px !important;
}

.book-participant-checkbox-cont {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.book-participant-tag-img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.book-participant-tag-img-cont {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  /* margin-right: 5px; */
}

.book-participant-tag-label {
  font-size: var(--font-size-15);
  color: #5B5B5B;
  font-weight: 500;
}

.book-admin-add-paricipants-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.res-cpo-overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 5000 !important;
}

.res-cpo-popup {
  background-color: white;
  padding: 25px;
  border-radius: 15px;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 200px;
  width: 30%;


  @media only screen and (max-width: 1340px) {
    width: 35%;
  }

  @media only screen and (max-width: 1140px) {
    width: 40%;
  }

  @media only screen and (max-width: 780px) {
    width: 50%;
  }

  @media only screen and (max-width: 480px) {
    width: 80%;
  }
}

.res-cpo-textAlignCenter {
  margin-top: 5px;
  text-align: left;
  margin-bottom: 8px;
  font-size: var(--font-size-18);
}

.res-cpo-textAlignLeft {
  text-align: left;
  font-size: var(--font-size-15);
}

.res-cpo-alignMiddle {
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
  word-break: keep-all;
  margin-bottom: 8px;
}

.res-cpo-p1Text {
  margin-bottom: 8px;
}

.res-cpo-justifyContentCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px 5px 0px;
}


.res-cpo-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.res-popup-refund {
  margin-bottom: 5px;
}

.fw-700 {
  font-weight: 700;
}

.mt-16 {
  margin-top: 16px;
}

.res-popup-reschedule {
  font-size: var(--font-size-16);
  font-weight: 600;
}

.promo-code-sibling {
  margin-bottom: 15px;
}