.cardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 15px;
    border-left: 4px solid;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    width: 100%;
}

.basicData {
    display: inline-flex;
    flex-direction: column;
    gap: 5px;
}

.packageName {
    font-size: var(--font-size-16);
    font-weight: 600;
}

.batchName {
    font-size: var(--font-size-14);
    font-weight: 500;
}

.timeRange {
    font-size: var(--font-size-14);
    font-weight: 500;
}