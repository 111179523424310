.toggle-container-div {
    margin-left: 20px; 
    margin-right: 20px; 
    margin-top: 5px;
}

.toggle-outer-div {
    display: flex; 
    background-color: #F8F8F8;
    padding: 4px; 
    width: fit-content; 
    border-radius: 10px;
}

.toggle-inner-div-selected {
    color: #7413BD; 
    border-radius: 8px; 
    border: 0.5px solid #7413BD; 
    padding: 5px 10px; 
    width: 200px;
    text-align: center;
    font-size: var(--font-size-14);
    font-weight: 700; 
    cursor: pointer;
    background-color: white;
}

.toggle-inner-div-unselected {
    color: #5B5B5B; 
    padding: 5px 10px; 
    width: 200px; 
    text-align: center;
    font-size: var(--font-size-14);
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.5s ease;
}

.margin-bottom-24 {
    margin-bottom: 24px; 
}

.margin-bottom-10 {
    margin-bottom: 10px; 
}

.selected-tooltip {
    font-size: var(--font-size-14); 
    font-weight: 500; 
    margin-left: 4px; 
}