.schedulePreview {
    padding: 10px 0px;
    margin: 10px 0px;
    display: flex;
    gap: 18px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}


.selectedDate {
    font-size: var(--font-size-18);
    font-weight: 600;
}