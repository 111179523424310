.pdfDrawerCross {
    height: 18px;
    width: 18px; 
    cursor: pointer;
}

.pdfDrawerCrossCont{
    display: inline-block;
}

.pdfViewerActions {
    width: 100%; 
    display: flex;
    justify-content: space-between;
    align-items: center;
}