.user-main-card {
    word-wrap: break-word;
    word-break: break-all;
    overflow-x: auto !important;
    width: 100%;
}

.user-card-container {
    border: 0.17px solid #E0E0E0;
    background-color: #fff;
    box-shadow: 0px 3.31px 20.69px 0px #00000012;
    width: 100%;
    margin: 0px 0px 30px 0px;
    border-radius: 8px;
    overflow: hidden; 
}

.user-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
}

.user-in-account-delete {
    height: 17px;
    width: 15px;
    margin-top: -5px;
    cursor: pointer;
    margin-left: 15px;
}

.user-in-account-edit {
    height: 18px;
    width: 18px;
    margin-top: -5px;
    cursor: pointer;
}

.custom-user-card-heading {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-bold);
    color: #7413BD;
}

.user-field-style {
    margin: 12px 12px 20px 12px;
}

.three-dots-div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;
    border-radius: 6px;
    padding: 5px 3px;
    cursor: pointer;
}

.three-dots {
    height: 16px;
    width: 18px;
}

.select-item-style {
    display: flex;
    padding-top: 3px;
    justify-content: center;
}

.delete-img-style {
    height: 16px;
    width: 14px;
    margin-top: -4px;
}

.link-img-style {
    height: 18px;
    width: 16px;
    margin-top: -2px;

    @media only screen and (max-width: 576px) {
        height: 16px !important;
        width: 14px !important; 
    }
}

.unlink-img-style {
    height: 18px;
    width: 16px;
    margin-top: -2px;

    @media only screen and (max-width: 576px) {
        height: 16px !important;
        width: 14px !important; 
    }
}

.options-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    color: #5B5B5B;
    height: 40px;
    width: 100%;
    padding: 0px 15px;

}

.border-bottom-gray {
    border-bottom: 0.5px solid #8D8D8D;
}


#custom-pop-over-style.ant-popover-inner {
    padding: 2px 0px 2px 0px !important;
}


.user-card-label-style {
    color: #8D8D8D;
    font-size: var(--font-size-15);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.user-linked-accounts-header {
    background-color: #F5F5F5;
    min-width: 992px !important;
    overflow-x: auto !important;
}

.user-linked-accounts-style {
    margin: 12px 12px 12px 12px;
}

.user-details-rows {
    min-width: 992px !important;
    overflow-x: auto !important;
}

.user-linked-account-row {
    border-bottom: 0.17px solid #E0E0E0;
}

.account-name-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.pop-content-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.account-profileIcon {
    /* margin-right: 12px; */
    height: 35px;
    width: 35px;
}

.user-label-container {
    display: flex;
}

.user-profileIcon {
    height: 45px;
    width: 45px;
    margin-right: 18px;
}

.user-hideshow-linked-accounts {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    border-top: 0.17px solid #E0E0E0;
}

.user-text-wrap {
    white-space: wrap;
    text-wrap: wrap;
    word-break: break-word;
}