.sticker-main-container {
    border: 0.5px solid #E8E8E8;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    /* margin: 14px; */
    display: flex;
    max-width: 250px;
    min-width: 200px;
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-left: 0px;
    margin-right: 20px;
    margin-top: 10px;
}

@media only screen and (max-width: 1000px) {
    .sticker-main-container {
        margin-left: 0px;
        margin-right: 0px;

    }
}



.subTitle-container {

    font-weight: 600;
    text-transform: capitalize;
    margin-left: 12px;
}