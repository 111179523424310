.mobCalendarMonthNav {
    display: inline-flex;
}

.monthNavPrev {
    display: inline-flex;
    align-items: center;
}

.monthNavNext {
    display: inline-flex;
    align-items: center;
}

.monthNavColor {
    fill: #7413BD;
}

.scheduleListView {
    padding: 10px 0px;
    margin: 15px 0px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.scheduleDateDiv {
    display: flex;
    gap: 18px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.listViewDate {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50px;
}

.listViewDateSchedules {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
}

.listViewDay {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #C7C7CC;
    font-size: var(--font-size-16);
    font-weight: 500;
}

.listViewDateNum {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #C7C7CC;
    font-size: var(--font-size-16);
    font-weight: 600;
}

.listViewDateSelected {
    color: black !important;
    font-size: var(--font-size-17) !important;
    font-weight: 700 !important;
}


.horizontalDivider {
    height: 1px;
    border-radius: 8px;
    width: 100%;
    background-color: #C7C7CC;
}

.noCalendarEventsContainer {
    margin-top: 100px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 400px) {
        margin-top: 70px !important;
    }
}

.noCalendarEvents {
    height: 216px;
    width: 320px;
}

.noCalendarEventsText {
    margin-top: 30px;
    font-size: 16px;
    color: #D0D0D0;
}

.explorePackagesText {
    display: inline-block;
    margin-top: 10px;
    color: #7413BD;
    font-size: var(--font-size-16);
}

.explorePurpleArrow {
    margin-left: 10px;
    width: 20px;
}