.fbs-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100dvh; 
    height: 100dvw;
    background-color: rgba(1, 1, 1, 0.3);
}

.fbs-invisible {
    display: none; 
    transition: 1s ease-in; 
}

.fbs-visible {
    display: block;     
}

.fbs {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    height: 76%;
    background-color: white;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.fbs-heading-container {
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 100%; 
    height: 50px;
    border-bottom: 1px solid #D0D0D0;
}

.fbs-footer-container {
    display: flex; 
    align-items: center;
    justify-content: flex-end;
    width: 100%; 
    height: 50px;
    border-top: 1px solid #D0D0D0;
}

.fbs-heading {
    color: #333333; 
    font-size: var(--font-size-18);
    font-weight: 600;
}

.close-fbs-icon-container {
    position: absolute;
    right: 15px;
    top: 17px;
    height: var(--font-size-20);
    width: var(--font-size-20);
}

.close-fbs-icon {
    height: var(--font-size-20);
    width: var(--font-size-20);
}

.filters-body-container {
    height: calc(100% - 100px);
    display: flex;
}

.filter-lists {
    height: 100%; 
    width: 160px; 
    background-color: #F8F8F8; 
    overflow-y: auto;
}

.selected-filter-name-options {
    height: 100%; 
    width: calc(100% - 160px); 
    background-color: white;
    overflow-y: auto;
}

.filter-name {
    color: #1A1A1A; 
    font-size: var(--font-size-18);
    font-weight: 400;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 4px 10px;
    border-bottom: 1px solid #D0D0D0;
}

.fbs-filter-count {
    color: #7413BD;
}

.filter-name-selected {
    border-left: 3px solid #7413BD;
    color: #1A1A1A;
    font-size: var(--font-size-18);
    background-color: white;
    font-weight: 550;
}

.filter-radio-option {
    padding: 5px 10px;
}

.custom-fbs-radio-group {
    padding: 0px 15px 10px 15px !important;
}

.custom-fbs-radio {
    padding: 8px 0px !important;
    width: 100%; 
}

.custom-fbs-checkbox {
    padding: 8px 12px !important;
    width: 100%; 
}


/* booking summary bottom sheet -> bsbs */

.bsbs-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100dvh; 
    height: 100dvw;
    background-color: rgba(1, 1, 1, 0.3);
    height: 100%;
}


.bsbs {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    min-height: 30%;
    max-height: 60%;
    background-color: white;
    overflow-y: auto;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.bsbs-invisible {
    display: none; 
    transition: 1s ease-in; 
}

.bsbs-visible {
    display: block;     
}


.bsbs-heading-container {
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 100%; 
    height: 50px;
    border-bottom: 1px solid #D0D0D0;
}

.bsbs-heading {
    color: #333333; 
    font-size: var(--font-size-18);
    font-weight: 600;
}

.close-bsbs-icon-container {
    position: absolute;
    right: 15px;
    top: 17px;
    height: var(--font-size-20);
    width: var(--font-size-20);
}

.close-bsbs-icon {
    height: var(--font-size-20);
    width: var(--font-size-20);
}