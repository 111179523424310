.stickers-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 18px;
    gap: 24px;
}

.subtitle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sticker-item {
    width: 358px;
    height: 100px;
    gap: 0px;
    border-radius: 12px 0px 0px 0px;
    opacity: 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px 0px #00000026;

}

.sticker-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.sticker-icon {
    width: 20px;
    height: 20px;
}

.sticker-title {
    font-family: Mulish;
    font-size: var(--font-size-18);
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.sticker-value {
    margin-top: 5px;
    font-family: Mulish;
    font-size: var(--font-size-20);
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.sticker-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DCFFBA;
    color: #4EA000;
    font-weight: bold;
    font-size: var(--font-size-20);
    border-radius: 16px;
    padding: 4px 12px;
}


.status-badge {
    width: 100%;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-family: Mulish;
    font-size: var(--font-size-14);
    font-weight: 600;
}

.bulk-action-icon {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    margin-bottom: -4px;
}

.invoice-date {
    white-space: nowrap;
}

.invoice-selector-search-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 25px;
    flex-wrap: wrap;
}

.inv-filters {
    margin-bottom: 25px;
    margin-top: 25px;
}

.invoice-link {
    color: #333;
    white-space: nowrap;
}

.invoice-link:hover {
    color: #333;
}

/* .sticker-badge {
   
    padding: 10px 0px 0px 0px;
    gap: 10px;
    border-radius: 100px 0px 0px 0px;
    opacity: 0px;
    background: #DCFFBA;
    color: #4EA000;
    font-family: Mulish;
    font-size: 20px;
    font-weight: 600;
    line-height: 11.23px;
    letter-spacing: -0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
} */

.sticker-badge.overdue {
    color: #8B4513;
    background: #FFAF764D;
}

.invoice-links {
    color: #7413BD;
    text-decoration: underline;
}

.invoice-dropdown-container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

.invoice-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

@media only screen and (max-width: 940px) {
    .mobile-payment-filters-row {
        display: flex !important;
    }

    .laptop-payment-filters-row {
        display: none !important;
    }
}

.laptop-payment-filters-row {
    white-space: nowrap;
}

.mobile-payment-filters-row {
    margin: 10px 0px;
    flex-direction: column;
    gap: 12px;
    display: none;
}



@media only screen and (max-width: 940px) {

    .payments-table-view {
        display: none !important;
    }

    .payments-list-view {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .invoice-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}

@media only screen and (max-width: 576px) {
    .mobile-inv-due-date-container {
        margin-right: 20px !important;
    }

    .mobile-inv-num-container {
        margin: 0px 20px !important;
    }
}

.payments-table-view {
    display: block;
    margin-bottom: 30px;
}

.payments-list-view {
    display: none;
}

.invoice-list-card {
    width: 100%;
    padding: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 10px;
}

.invoice-card-1st-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.inv-basic-detail-container {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 360px) {
        max-width: 162px;
    }
}

.inv-pkg-name {
    font-weight: 600;
    font-size: var(--font-size-16);
    padding: 5px;
    display: inline-flex;
    flex-wrap: wrap;
}

.inv-sub-details-container {
    display: flex;
    gap: 10px;
    padding: 4px;
}

.inv-user-img-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.inv-user-name {
    font-size: var(--font-size-14);
}

.inv-data {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.mobile-inv-raise-date {
    font-size: var(--font-size-14);
    color: #8D8D8D;
    display: inline-flex;
    gap: 5px;
    padding-top: 5px;
    align-items: center;
}

.inv-calendar-img-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.mobile-inv-total {
    display: inline-flex;
    font-size: var(--font-size-16);
    font-weight: 500;
    justify-content: flex-end;
}

.mobile-inv-status {
    font-size: var(--font-size-14);
    display: inline-flex;
    justify-content: flex-end;
}

.inv-main-heading {
    font-weight: 600;
    font-size: var(--font-size-15);
    line-height: 17.57px;
    letter-spacing: 0%;
    color: #333333;
}

.mobile-inv-horizontal-divider {
    height: 1px;
    background-color: #D0D0D0;
    border-radius: 50%;
    width: 100%;
    margin: 10px 0px;
}


.invoice-card-2nd-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.inv-2nd-row-1st-col {
    display: inline-flex;
}


.mobile-inv-due-date-container {
    font-size: var(--font-size-14);
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    margin-right: 40px;
}

.mobile-inv-due-date-header {
    color: #8D8D8D;
}

.mobile-inv-vertical-divider {
    height: 40px;
    background-color: #D0D0D0;
    border-radius: 50%;
    width: 1px;
}


.mobile-inv-num-container {
    font-size: var(--font-size-14);
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    margin: 0px 40px;
}

.purple-download-container {
    display: inline-flex;
    gap: 4px;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
}

.inv-purple-download {
    font-size: var(--font-size-14);
    color: #7413BD;
}

.inv-download-img-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}


.inv-user-img {
    height: var(--font-size-18);
    width: var(--font-size-18);
}

.inv-calendar-img {
    height: var(--font-size-18);
    width: var(--font-size-18);
}

.inv-download-img {
    height: var(--font-size-16);
    width: var(--font-size-16);
}

.inv-main-body {
    font-family: Mulish;
    font-weight: 500;
    font-size: var(--font-size-15);
    line-height: 17.57px;
    letter-spacing: 0%;
    color: #333333;
}