.viewAlign {
    margin-left: 10px; 
}

.packageDetails {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-top: 16px;
}

.packageCircle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}


.packageName {
    font-size: var(--font-size-18);
    font-weight: 600;
}

.basicDetails {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 15px 0px;
}

.displayFlex {
    display: flex;
    align-items: center;
}

.instructorsContainer {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.scheduleImg {
    height: 15px; 
    width: 15px; 
    margin-right: 12px;
}

.clockImg {
    height: 15px; 
    width: 15px; 
    margin-right: 12px;
}

.drawerScheduleDetail {
    font-size: var(--font-size-15);
    font-weight: 500;
    color: #5b5b5b;
}

.horizontalDivider {
    width: 100%;
    height: 1px;
    border-radius: 8px;
    background-color: #C7C7CC;      
}

.scheduleDataContainer {
    margin: 15px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #5b5b5b;
    font-size: var(--font-size-15);
}

.generalFlexRow {
    display: flex;
    align-items: center;
    gap: 12px;
}

.general1stCol {
    font-weight: 400;
    display: inline-flex;
    gap: 12px;
}

.flexImgContainer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.studentProfileImg {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    object-fit: cover;
}

.bookedForUserWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
  
.bookedForUsersContainer {
    margin: 15px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.bookedForUserContainer {
    display: flex;
    align-items: center;
    gap: 12px;
}