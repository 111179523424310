.ep-spinDiv {
    height: 100vh;
}

.ep-packageList1 {
    margin-top: 20px;
    padding-left: 100px;
    padding-right: 100px;
}

.ep-empty {
    width: 100%;
}

.ep-empty .ep-card {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #E8E8E8;
    box-shadow: 0 0 10px rgba(207, 207, 207, 0.5);
    display: flex;
    justify-content: center;
    padding-top: 35px;
    padding-bottom: 35px;
    width: 100%;
}

.ep-empty .ep-contentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.ep-empty .ep-mainHeading {
    font-size: var(--font-size-16);
    color: #333333;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ep-empty .ep-headingDescription {
    color: #8D8D8D;
    font-size: var(--font-size-16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}