@media only screen and (max-width: 670px) {

    .location-icon {
        height: var(--font-size-17);
        width: var(--font-size-17);
    }

    .location-display-name {
        font-size: var(--font-size-15) !important;
    }

    .entity-date-time-container {
        font-size: var(--font-size-15) !important;
    }

    .mobile-book-now {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 65px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.17) 0px 4px 30px 0px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px 15px;
        border-radius: 8px 8px 0px 0px;
        z-index: 100;
        background-color: white;
    }

    .calendar-icon-container {
        align-items: flex-start !important;
    }

    .tab-lap-book-now-btn {
        display: none;
    }

    .bottom-filler {
        height: 60px !important;
    }

    .description-box-content {
        padding: 15px 8px 15px 15px !important;
    }

    .book-participant-container {
        padding-left: 18px !important;
    }

    .terms-conditions-content {
        padding: 5px 5px 5px 5px !important;
    }

    .second-row-detail-view {
        margin-top: 6px !important;
        flex-direction: column !important;
        gap: 4px !important;
    }

    .second-row-col-1 {
        gap: 4px !important;
    }

    .age-range-container,
    .user-color-div {
        font-size: var(--font-size-15) !important;
    }

    .age-range-text {
        font-size: var(--font-size-15) !important;
    }

    .package-name-label {
        font-size: var(--font-size-18) !important;
    }

    .upcoming-slot-container {
        padding-bottom: 0px !important;
    }

    .vertical-divider {
        height: 12px !important;
    }

    .mobile-sticky-bar {
        padding: 15px 15px 10px 15px !important;
    }
}

@media only screen and (max-width: 768px) {
    .location-display-name {
        margin: 4px 0px 0px 0px !important;
    }

    .lap-tab-sticky-bar {
        display: none !important;
    }
}

@media only screen and (min-width: 769px) {
    .mobile-sticky-bar {
        display: none !important;
    }
}

@media only screen and (min-width: 671px) and (max-width: 768px) {
    .second-row-detail-view {
        margin-top: 8px !important;
        flex-direction: column !important;
        gap: 5px !important;
    }
}

@media only screen and (min-width: 671px) {
    .mobile-book-now {
        display: none;
    }
}

.view-text {
    font-style: mulish;
    font-weight: 600;
    font-size: var(--font-size-16);
    color: #5b5b5b;
}

.description-box-content {
    padding: 26px;
    font-weight: 500;
    font-size: var(--font-size-16);
    color: #333333;
    text-wrap: wrap;
    text-align: left;
}

.custom-detail-box {
    @media only screen and (max-width: 768px) {
        padding: 0px !important;
    }
}

.bottom-filler {
    height: 80px;
    width: 100%;
}

.comma {
    display: inline;
}

.entity-date-time-container {
    display: flex;
    font-size: var(--font-size-16);
    align-items: center;
    height: 100%;
}

.gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px !important;
}

.lap-tab-sticky-bar {
    position: sticky;
    display: flex;
    justify-content: center;
    top: 53px;
    width: 100%;

    background: white;
    box-shadow: 0px 4px 25px 0px #0000000F;
    padding: 16px;
    z-index: 100;
    flex-direction: column;
}

.entity-basic-details {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image-previewer-container {
    width: 100%;
}

.image-previewer-toolbar {
    display: flex;
    align-items: center;
    gap: 10px;
}

.calendar-icon-container,
.age-range-icon-container,
.user-icon-container {
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.age-range-container,
.user-color-div {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-size: var(--font-size-16);
}

.user-container {
    line-height: 30px;
    display: flex;
    align-items: center;
}

.calendar-icon,
.age-range-icon,
.batch-icon,
.currency-icon {
    width: var(--font-size-18);
    height: var(--font-size-18);
}

.about-heading,
.cancellation-policy-heading {
    font-size: var(--font-size-20);
    font-weight: 700;
    margin-bottom: 16px;
}

.package-price-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.age-range-text {
    font-weight: 500;
    white-space: nowrap;
    color: #333333;
    font-size: var(--font-size-16);
}

.user-icon {
    width: var(--font-size-16);
    height: var(--font-size-18);
}

.entity-start-end-date {
    display: flex;
    align-items: center;
    gap: 8px;
}

.mobile-sticky-bar {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    padding: 10px 20px;
    background: white;
    box-shadow: 0px 4px 25px 0px #0000000F;
    position: sticky !important;
    top: 51px;
}

.lap-tab-sticky-bar-content {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0px 10px;
    gap: 12px;
    width: calc(100% - 150px);
}

.buttons-container {
    width: 100px;
    display: inline-flex;
    justify-content: flex-end;
}

.onwards-text-detail-view {
    font-size: var(--font-size-16);
}

.week-days-line {
    display: flex;
    gap: 10px;
}

.week-day-cell {
    padding: 4px 8px !important;
    font-size: var(--font-size-14) !important;
    border-radius: 8px !important;
}

.package-name-label {
    color: #333;
    font-weight: 700;
    font-size: var(--font-size-24);
    margin-bottom: 2px;
}


.divider .ant-divider-horizontal {
    margin: 10px 0px !important;
}

.adjust-divider {
    margin-top: -5px;
}

.location-container {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.location-display-name {
    color: #333333;
    font-size: var(--font-size-16);
    display: inline-flex;
    gap: 8px;
}

.fit-container-gap {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.divider-line {
    background-color: gray;
    width: 1px;
    height: 100%;
    border-radius: 5px;
    margin: 0px 9px;
}

.terms-and-conditions-container {
    height: 44px;
    background: #F4F6FD;
    border-radius: 0px 0px 8px 8px;
    display: flex !important;
    align-items: center;
    padding: 26px;
    font-size: var(--font-size-16);
}

.terms-conditions-text {
    font-weight: 400px;
    color: #7413bd;
    padding-left: 7px;
    cursor: pointer;
}

.terms-conditions-content {
    padding: 10px 10px 15px 10px;
    font-size: var(--font-size-16);
}

.package-cover-image .ant-image {
    width: 100% !important;
}

.location-div {
    display: flex;
    gap: 8px;
}

.booked-for-user-color-div {
    background-color: #FFD5BF80;
    padding: 2px 10px;
    border-radius: 15px;
    color: #AB5B00;
    font-size: var(--font-size-15);
    height: 26px;
    display: flex;
    align-items: center;
}

.booked-batch-name {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
    font-size: var(--font-size-15);
}

.batch-icon-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.invoice-paid-amount-container {
    display: flex;
    gap: 10px;
    width: fit-content;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-15);
}

.dollar-icon-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.dollar-icon,
.location-icon {
    height: var(--font-size-18);
    width: var(--font-size-18);
}

.view-invoice {
    color: #7413BD;
    padding: 5px 0px;
    font-size: var(--font-size-15);
    cursor: pointer;
}

.vertical-divider {
    background-color: #D0D0D0;
    width: 1.5px;
    height: 16px;
    border-radius: 8px;
    margin: 3px 0px 0px 5px;
}

.vertical-divider-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.upcoming-slot-heading {
    font-weight: 600;
    font-size: var(--font-size-15);
}

.upcoming-slot-date-time {
    font-size: var(--font-size-15);
    padding: 0px 0px 0px 8px;
}

.first-row-detail-view {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.package-name-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
}


.view-schedule-text {
    color: #7413BD;
    padding: 5px 10px 0px 0px;
    font-size: var(--font-size-15);
    cursor: pointer;
}

.status-name-container {
    padding: 2px 10px;
    border-radius: 8px;
    color: white;
    margin-top: 5px;
    font-size: var(--font-size-15);
}

.second-row-detail-view {
    gap: 12px 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.second-row-col-1 {
    gap: 24px;
    display: flex;
    flex-direction: row;
}

.third-row-detail-view {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
}

.upcoming-slot-container {
    display: inline-flex;
    align-items: center;
}


.data-container {
    display: flex;
}

.data-label {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: var(--font-size-15);
}

.data-value {
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size-15);
    padding: 0px 0px 0px 8px;
}

.book-fontWeight600 {
    font-weight: 550;
}

.book-fontWeightlight {
    font-weight: lighter;
}

.no-upcoming-slot-container {
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size-16);
}


.book-participant-container {
    padding-left: 26px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.pbm-cpo-overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 5000 !important;
}

.pbm-cpo-popup {
    background-color: white;
    padding: 25px;
    border-radius: 15px;
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 200px;
    width: 35%;

   
    @media only screen and (max-width: 1340px) {
        width: 40%;
    }
    @media only screen and (max-width: 1140px) {
        width: 50%;
    }
    @media only screen and (max-width: 780px) {
        width: 60%;
    }
    @media only screen and (max-width: 480px) {
        width: 80%;
    }
}

.pbm-cpo-textAlignCenter {
    margin-top: 5px;
    text-align: left;
    margin-bottom: 8px;
    font-size: var(--font-size-18);
}

.pbm-cpo-textAlignLeft {
    text-align: left;
    font-size: var(--font-size-15);
}

.pbm-cpo-alignMiddle {
    margin: 20px 0px 20px 0px;
    display: flex;
    justify-content: center;
    word-break: keep-all;
    margin-bottom: 8px;
}

.pbm-cpo-p1Text {
    margin-bottom: 8px;
}

.pbm-cpo-justifyContentCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0px 5px 0px;
}


.pbm-cpo-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.popup-refund{
    margin-bottom: 5px;
}

.fw-700 {
    font-weight: 700;
}

.mt-16 {
    margin-top: 16px;
}
.popup-reschedule{
    font-size: var(--font-size-16);
    font-weight: 600;
}