.infoIconStyle {
    width: 18px;
    height: 18px;
    margin-left: 15px;
    cursor: pointer;
}

.student-infoIconStyle {
    width: 18px;
    height: 18px;
    margin-left: 15px;
    cursor: pointer;
}

.filterRow2 {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 25px;
    min-height: 80px;
    padding-top: 20px;
}

.margin-left-4 {
    margin-left: 4px;
}

.student-label {
    margin-bottom: 8px;
    font-size: var(--font-size-14);
}

.filterRow {
    margin-top: 25px;
    margin-bottom: 25px;
}

.filterCol {
    padding-left: 10px;
    padding-right: 10px;
}

.filterLastCol {
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    float: right;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    cursor: pointer;
}

.filterP {
    color: #7413BD;
    font-size: var(--font-size-15);
    padding-right: 10px;
}

.filterImg {
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin-right: 8px;
}

.filterCount {
    border-left: 1px solid black;
    padding-left: 10px;
}

.backStyle {
    color: #8D8D8D;
    cursor: pointer;
}

.rightIconStyle {
    margin: 0px 8px 0px 8px
}

.tab-container {
    margin-top: 20px;
}

.filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.date-filters-container {
    display: flex;
    align-items: center;
}

.date-filters-style {
    width: 100%;
    margin-left: 12px;
}

.flex-style {
    display: flex;
    border: 1px solid #D0D0D0;
    width: fit-content;
    border-radius: 5px;
}

.profileIcon {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.name-column {
    display: flex;
    align-items: center;
}

.role-style {
    font-size: var(--font-size-14);
    color: #8D8D8D;
}

.title-container {
    margin-left: 15px;
}

.toggle-status-col {
    display: flex;
    align-items: center;
}

.toggle-status-options {
    cursor: pointer;
}

.profile-photo-style {
    width: 100px;
    height: 100px;
    object-fit: fill;
    border-radius: 6px;
}

.profile-title-container-account {
    display: flex;
    align-items: center;
    border-right: 1.5px solid #D0D0D0;
    padding: 0 12px;
}

.profile-title-container-user {
    display: flex;
    align-items: center;
    padding: 0 12px;
}

.prefeeredName-style {
    color: #5B5B5B;
    font-size: var(--font-size-16);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.status-style {
    margin-top: 18px;
}

.student-name-style {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.profile-label-style {
    color: #8D8D8D;
    font-size: var(--font-size-14);
    text-transform: capitalize;
    margin: 0px 10px 10px 10px;
}

.detail-style {
    display: flex;
    align-items: center;
}

.value-container {
    margin-left: 20px;
}

.profile-value-style {
    font-size: var(--font-size-14);
    margin: 0px 10px 10px 10px;
}

.profile-detail-container-account {
    padding: 0 12px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

.profile-detail-container-user {
    padding: 0 12px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
}

.profile-action-style {
    height: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width:990px) {
        padding-top: 10px;
    }
}

.editIconStyle {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.editIcon-container {
    border: 1px solid #D0D0D0;
    padding: 0px 8px;
    border-radius: 5px;
    width: fit-content;
    height: 36px;
    background-color: #FFF;
    display: flex;
    align-items: center;
}

.popup-confirm {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    /* border: 2px solid red */
}

.parent-container {
    display: flex;
    justify-content: space-between;
}

.parent-style {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px 0px 10px;
}

.confirmStyle {
    font-size: var(--font-size-20);
    font-weight: 600;
    margin-bottom: 12px;
}

.studentlist-filter {
    margin-bottom: 15px;
    margin-right: 8px;
}


.whiteSpace-noWrap {
    white-space: nowrap;
}


@media only screen and (max-width:1000px) {
    .filterCol {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .filterRow2 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .filterLastCol {
        margin-top: 20px;
    }

    .profile-title-container-account {
        display: flex;
        align-items: center;
        border-right: none;
        border-bottom: 1.5px solid #D0D0D0;
        padding-bottom: 12px;
        margin-bottom: 15px;
        justify-content: center;
    }

    .profile-title-container-user {
        display: flex;
        align-items: center;
        border-right: none;
        padding-bottom: 12px;
        justify-content: center;
    }

    .student-name-style {
        margin-left: 6px;
    }
}

.moreP {
    color: #8D8D8D;
}

.rightAligned {
    display: flex;
    justify-content: flex-end;
}

.account-name-column {
    display: flex;
    align-items: center;
}

.select-green {
    background-color: #14CCA1;
    border-radius: 8px;
    height: 34px;
}

.account-status-container {
    background-color: #14CCA1;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 34px;
    padding: 0px 15px;

    @media only screen and (max-width: 576px) {
        height: 28px;
        
    }
}

.select-green.ant-select {
    height: 34px !important;
}

.three-dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;
    border-radius: 6px;
    padding: 5px 3px;
    cursor: pointer;
}

.delete-icon-style {
    height: 16px;
    width: 14px;
    margin-top: -2px;
}

.link-icon-style {
    height: 18px;
    width: 16px;
    
    @media only screen and (max-width: 576px) {
        height: 16px !important;
        width: 14px !important; 
    }
}

.unlink-icon-style {

    height: 18px;
    width: 16px;

    @media only screen and (max-width: 576px) {
        height: 16px !important;
        width: 14px !important; 
    }
}

.custom-regular-font {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-regular);

    @media only screen and (max-width: 576px) {
        font-size: var(--font-size-15) !important;
    }
}

.icon-container-style {
    display: flex;
    align-items: center;
    justify-content: center;
}

.options-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    color: #5B5B5B;
    height: 40px;
    width: 100%;
    padding: 0px 15px;

}

.border-bottom-divider {
    border-bottom: 0.5px solid #8D8D8D;
}


#custom-pop-over-style.ant-popover-inner {
    padding: 2px 0px 2px 0px !important;
}

.linked-accounts-three-dots {
    height: 16px;
    width: 18px;
}

.select-green .ant-select-selector {
    background-color: transparent !important;
    border: 1px solid #14CCA1 !important;
    height: 34px !important;
    color: white !important;
}

.select-green .ant-select-arrow {
    color: white !important;
}

.account-profileIcon {
    margin-right: 12px;
    height: 35px;
    width: 35px;
}

.account-margin-bottom {
    margin-bottom: 10px;
}

.account-link-ul {
    /* margin-left: 12px; */
    color: #8D8D8D;
    font-size: var(--font-size-14);
}

.account-link-confirm {
    margin: 12px 0px 6px 0px;
}

.account-text-wrap {
    text-wrap: wrap;
    white-space: wrap;
    word-break: break-word;
}