.cpos-imageBack {
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #F8F8F8;
    cursor: pointer;
}

.cpos-popOption-image {
    width: 18px;
    height: 18px;
    margin-right: 12px;
    object-fit: contain;

}


.cpos-listitem-popOptions .ant-list-item {
    justify-content: flex-start !important;
    cursor: pointer;
    border: none !important;
    padding: 10px 5px !important;
    font-size: var(--font-size-14) !important;
    font-weight: 400;
    align-items: center !important;
}