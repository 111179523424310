.mainCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 3.31px 20.69px 0px #00000012;
    border: 0.17px solid #E0E0E0;
    word-wrap: break-word;
    word-break: break-all;
    margin: 30px 0px 0px 0px;
    overflow: hidden;
    width: 100%;

    @media only screen and (max-width: 576px) {
        margin: 20px 0px 20px 0px;
    }
    @media only screen and (min-width: 577px) and (max-width: 940px) {
        margin: 25px 0px 25px 0px;
    }
}

.cardHeader {
    border-bottom: 0.5px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 10px 20px;
}