.subheader {
    padding: 20px 0px 16px 0px;
    /* text-decoration: underline; */
    font-size: var(--font-size-18);
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.form-imgStyle {
    height: 60px;
    object-fit: cover;
}

.iconStyle {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.form-imgContainer {
    border: 1px solid #D0D0D0;
    padding: 12px;
    display: flex;
    border-radius: 10px;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
    align-items: center;
}

.form-wrapper-main-row label {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.form-wrapper-main-row .ant-col {
    padding-bottom: 0 !important;
}