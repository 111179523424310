.booking-confirmation-overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 5000 !important;
}

.booking-confirmation-popup {
    background-color: white;
    padding: 25px;
    border-radius: 31px;
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 200px;
    width: 50%;

    @media only screen and (max-width:1000px) {
        width: 60%;
    }

    @media only screen and (max-width:900px) {
        width: 70%;
    }

    @media only screen and (max-width:800px) {
        width: 80%;
    }

    @media only screen and (max-width:700px) {
        width: 90%;
    }

    @media only screen and (max-width:475px) {
        width: 95%;
    }
}


.confirm-booking-container {
    display: flex;
}

.confirm-booking-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 50%
}

.confirm-booking-title {
    font-weight: 700;
    font-size: var(--font-size-16);
    color: #000000;
}

.confirm-booking-right-title {
    font-weight: 700;
    font-size: var(--font-size-16);
    color: #000000;
}

.confirm-booking-left-subtitle {
    font-weight: 700;
    font-size: var(--font-size-16);
    color: #5B5B5B;
    text-align: center;
}

.confirm-booking-icon {
    width: 63px;
    height: 63px;
}

.confirm-booking-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    box-shadow: 0px 4px 25px 0px #0000000F;
    background: #F8F8F8;
    padding: 20px;
    width: 50%
}

.booking-inf-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.confirm-booking-close-btn {
    position: absolute !important;
    top: 27px;
    right: 27px;
    z-index: 1;
}

.booking-detail-title {
    font-weight: 700;
    font-size: var(--font-size-14);
    color: #6B7280;
}

.booking-detail-inf {
    font-weight: 700;
    font-size: var(--font-size-14);
    color: #000000;
}

.confirm-booking-right-subcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    padding: 20px;

}

.confirm-booking-margin-top {
    margin-top: 30px;
}

.confirm-booking-inf-container {
    max-height: 450px;
    overflow-y: auto;

    @media only screen and (max-width:475px) {
        max-height: 400px;
    }
}



.participant-inf {
    display: flex;
}