.cardContainer {
    border: 0.17px solid #E0E0E0;
    background-color: #fff;
    box-shadow: 0px 3.31px 20.69px 0px #00000012;
    width: 100%;
    margin: 0px 0px 30px 0px;
    border-radius: 8px;
    overflow: hidden; 
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
}

.headingText {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-bold);
    color: #7413BD;
}

.deleteIcon {
    height: 17px;
    width: 15px;
    margin-top: -5px;
    cursor: pointer;
    margin-left: 15px;
}

.editIcon {
    height: 18px;
    width: 18px;
    margin-top: -5px;
    cursor: pointer;
}

.hideshowLinkedAccounts {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    font-size: var(--font-size-16);
    border-top: 0.17px solid #E0E0E0;
}