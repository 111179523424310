.transaction-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

@media only screen and (max-width: 992px) {

    .transaction-container {
        gap: 15px;
    }

    .transactions-table-view {
        display: none !important;
    }

    .transactions-list-view {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
}

.transactions-table-view {
    display: block;
}

.transactions-list-view {
    display: none;
}

.tsx-main-heading {
    font-weight: 600;
    font-size: var(--font-size-15);
    line-height: 17.57px;
    letter-spacing: 0%;
    color: #333333;
}

@media only screen and (max-width: 940px) {
    .mobile-payment-filters-row {
        display: flex !important;
    }

    .laptop-payment-filters-row {
        display: none !important;
    }
}

.laptop-payment-filters-row {
    white-space: nowrap;
}

.mobile-payment-filters-row {
    margin: 10px 0px;
    flex-direction: column;
    gap: 12px;
    display: none;
}


.transact-list-card {
    width: 100%;
    padding: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 10px;
}

.transact-card-1st-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.transact-basic-detail-container {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 380px) {
        max-width: 180px;
    }
}

.transact-pkg-name {
    font-weight: 600;
    font-size: var(--font-size-16);
    padding: 5px;
    display: inline-flex;
    flex-wrap: wrap;
}

.transact-id-container {
    display: flex;
    gap: 10px;
    padding: 4px;
}

.transact-id {
    color: #8D8D8D;
    font-size: var(--font-size-14);
}

.transact-data {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;
    height: 50px;
    justify-content: center;
}

.mobile-transact-raise-date {
    font-size: var(--font-size-14);
    color: #8D8D8D;
    display: inline-flex;
    gap: 5px;
    align-items: center;
}

.transact-calendar-img-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.mobile-transact-amount {
    display: inline-flex;
    font-size: var(--font-size-16);
    font-weight: 500;
    justify-content: flex-end;
    text-align: end;
}

.mobile-transact-inv-date {
    font-size: var(--font-size-14);
    color: #8D8D8D;
    display: inline-flex;
    gap: 5px;
    align-items: center;
}

.mobile-transact-status {
    font-size: var(--font-size-14);
    display: inline-flex;
    justify-content: flex-end;
}


.mobile-transact-horizontal-divider {
    height: 1px;
    background-color: #D0D0D0;
    border-radius: 50%;
    width: 100%;
    margin: 8px 0px 10px 0px;
}


.transact-card-2nd-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0px;
}

.transact-2nd-row-1st-col {
    display: inline-flex;
}


.mobile-transact-due-date-container {
    font-size: var(--font-size-14);
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    margin-right: 40px;
}

.mobile-transact-due-date-header {
    color: #8D8D8D;
}

.mobile-transact-vertical-divider {
    height: 15px;
    background-color: #D0D0D0;
    border-radius: 50%;
    width: 1px;
}


.mobile-transact-num-container {
    font-size: var(--font-size-14);
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    margin: 0px 40px;
}

.transact-payment-mode-container {
    display: inline-flex;
    gap: 6px;
    justify-content: flex-end;
    align-items: center;
}

.transact-payment-mode-text {
    font-size: var(--font-size-14);
    color: #8D8D8D;
}

.transact-payment-mode-img-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ECEFF2;
    border-radius: 4px;
    padding: 2px 6px;
}


.transact-calendar-img {
    height: var(--font-size-18);
    width: var(--font-size-18);
}

.transact-dollar-icon-img {
    height: 16px;
    width: 16px;
}

.transact-card-icon-img {
    height: 14px;
    width: 16px;
    padding-top: 0.5px;
}

/* .transact-dollar-icon-img {
    height: var(--font-size-17);
    width: var(--font-size-17);
}
.transact-dollar-icon-img {
    height: var(--font-size-17);
    width: var(--font-size-17);
} */