.noLinkedUsersContainer {
    margin-top: 5vh;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noLinkedUsers {
    width: 310px;
    height: 302px;
}