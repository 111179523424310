.invoicePopOptions .ant-list-item {
    justify-content: flex-start !important;
    cursor: pointer;
    border: none !important;
    padding: 10px 5px !important;
    font-size: var(--font-size-14) !important;
    font-weight: 400;
    align-items: center !important;
}

.inv-pop-icon {
    height: 25px; 
    width: 25px; 
    margin: 0px 6px 0px 0px;
}