.topbarNotification {
    height: 500px;
    width: 400px;
    overflow: scroll;

    @media only screen and (max-width: 360px) {
        width: 280px;
    }

    @media only screen and (min-width: 361px) and (max-width: 400px) {
        width: 310px;
    }

    @media only screen and (min-width: 401px) and (max-width: 500px) {
        width: 350px;
    }
}

.notification-loading {
    margin-top: 24px;
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 24px;
}

.isoDropdownHeader {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: center;
}

.isoDropdownListItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
}

.notification-main {
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
}

.notification-subMain {
    display: flex;
    align-items: center;
    gap: 16px;
}

.notification-body {
    font-size: var(--font-size-15);
    font-weight: 400;
    color: #333;
    word-break: break-word;
    white-space: normal;
}

.notification-date {
    font-size: var(--font-size-13);
    font-weight: 300;
    color: #8D8D8D;
}

.isoIconWrapper {
    display: flex;
    align-items: center;
}

.topbar-notification-popover .ant-popover-content {
    @media only screen and (max-width: 500px) {
        margin: 0px 16px !important;
    }
}