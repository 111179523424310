@media only screen and (max-width: 576px) {
    .field-style-acc {
        margin: 0px !important;
    }
    .main-card-acc {
        margin: 20px 0px 0px 0px !important;
    }
    .card-header-acc {
        padding: 15px 20px !important;
    }
}

@media only screen and (min-width: 577px) and (max-width: 940px) {
    .field-style-acc {
        margin: 0px !important;
    }
    .main-card-acc {
        margin: 25px 0px 0px 0px !important;
    }
    .card-header-acc {
        padding: 15px 20px !important;
    }
}


.main-card-acc {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 3.31px 20.69px 0px #00000012;
    border: 0.17px solid #E0E0E0;
    word-wrap: break-word;
    word-break: break-all;
    margin: 25px 0px 0px 0px;
    overflow: hidden;
    width: 100%;
}

.card-header-acc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionStyle {
    display: flex;
    align-items: center;
}

.editIconStyleAbsolute {
    width: 18px;
    height: 18px;
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 20px;

    @media only screen and (max-width: 576px) {
        width: 15px; 
        height: 15px; 
        top: 18px; 
        right: 20px;
    }
}

.deleteIconStyle {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 15px;
}

.card-label-style-acc {
    color: #8D8D8D;
    font-size: var(--font-size-15);
    font-style: normal;
    line-height: normal;
    margin-bottom: 12px;

    @media only screen and (max-width: 576px) {
        margin-bottom: 2px;
        font-weight: 600;
    }

    @media only screen and (min-width: 577px) and (max-width: 940px) {
        margin-bottom: 8px;
        font-weight: 600;
    }

    @media only screen and (min-width: 941px) {
        font-weight: 600;
    }
}

.card-value-style-acc {
    color: #333;
    font-size: var(--font-size-15);
    font-style: normal;
    line-height: normal;

    @media only screen and (min-width: 940px) {
        font-weight: 600px;
    }
}

.field-style-acc {
    margin: 12px 12px 20px 12px;
}