.threeDots {
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #F8F8F8;
    cursor: pointer;
}

.threeDotsContainer {
    position: absolute;
    top: 15px;
    right: 20px;

    @media only screen and (min-width: 576px) {
        top: 20px !important; 
    }
}

.accountUserMetaDataContainer {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap; 
    gap: 15px; 
    padding: 10px 20px 20px 20px;

    @media only screen and (max-width: 400px) {
        padding: 15px 20px 15px 20px !important;
        grid-template-columns: 1fr !important;
    }

    @media only screen and (min-width: 401px) and (max-width: 576px) {
        padding: 15px 20px 15px 20px !important;
        grid-template-columns: 1fr 1fr !important;
    }
    
    @media only screen and (min-width: 577px) and (max-width: 940px) {
        grid-template-columns: 1fr 1fr !important;
        padding: 10px 50px 15px 20px !important;
    }
}

.containerBorderBottom {
    border-bottom: 1px solid #E0E0E0;
}

.metaData {
    display: flex; 
    flex-direction: column; 
}

.heading {
    font-size: var(--font-size-15);
    color: #8D8D8D; 
}

.value {
    display: inline-flex; 
    gap: 10px; 
    font-size: var(--font-size-15);
    color: #333333; 
}

.valueText {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
}

.imgContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.imgIcon {
    border-radius: 50%;
    height: 25px; 
    width: 25px;
}

.emptyImg {
    border-radius: 50%;
}

.bold {
    font-weight: 600;
}

.imgValue {
    display: inline-flex; 
    gap: 10px; 
    margin-top: 5px;
    font-size: var(--font-size-15);
    color: #333333; 
}