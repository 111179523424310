@media only screen and (max-width: 576px) {
    .profilePhotoStyle {
        width: 85px;
        height: 85px;
    }
}


.accountDetailsContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.accountStatusName {
    margin-top: 10px;
    font-size: 13px;
}

.accountNameStyle {
    margin-left: 15px; 
}

.profilePhotoStyle {
    width: 100px;
    height: 100px;
    object-fit: fill;
    border-radius: 6px;
}

.editIconContainer {
    border: 1px solid #D0D0D0;
    padding: 0px 8px;
    border-radius: 5px;
    width: fit-content;
    height: 32px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
}


.editIconStyle {
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.detailStyle {
    display: flex;
    align-items: center;
}

.profileLabelStyle {
    color: #8D8D8D;
    font-size: var(--font-size-14);
    text-transform: capitalize;
    margin: 0px 10px 10px 10px;
}


.valueContainer {
    margin-left: 20px;
}

.profileValueStyle {
    font-size: var(--font-size-14);
    margin: 0px 10px 10px 10px;
}

.accountDetailsContainer2 {
    display: flex;
    align-items: center;
    margin-top: 13px;
}

.noLinkedAccountsContainer {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noLinkedAccounts {
    width: 310px;
    height: 311px;
}