.onboarding-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
    height: calc(100vh - var(--desktop-topbar-height));
}

.onboarding-main-title {
    font-weight: 800;
    font-size: var(--font-size-32);
    width: 100%;
}

.onboarding-title {
    font-weight: 800;
    font-size: var(--font-size-32);
}

.onboarding-subContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.onboardingHeadingContainer {
    display: flex;
    width: 100%;
    max-width: 1210px;
}

.onboardingSkipContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    max-width: 1210px;
}



.progress-section {
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid #D0D0D0;
    border-radius: 13px;
    width: 100%;
    height: 100%;
}

.progress-bar {
    padding: 20px;
    border-radius: 13px;
    background: #f9f0ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    height: 100px;
}

.progress-bar-container {
    width: 100%;
}

.progress {
    display: flex;
    gap: 40px;
    width: 100%;
    height: 20px;
}


.onboarding-tasks {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 47px;
    cursor: pointer;
}

.task-item {
    display: flex;
    gap: 20px;
}


.task-item-title {
    font-weight: 600;
    font-size: var(--font-size-16);
    color: #000000;
    display: flex;
    align-items: center;
}

.task-item-title-coming-soon {
    font-weight: 600;
    font-size: var(--font-size-16);
    color: #000000;
    display: flex;
    align-items: center;
}

.tasks input[type="checkbox"] {
    margin-right: 10px;
}

.onboarding-actions-section {
    display: flex;
    flex-direction: column;
    /* padding: 0px 20px; */
    flex: 1;
    background: #fff;
}

.actions-section h2 {
    margin-bottom: 20px;
}

.onboarding-action-container {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D0D0D0;
    padding: 20px;
    gap: 20px;
}

.onboarding-action-container-coming-soon {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D0D0D0;
    padding: 20px;
    gap: 20px;
    background-color: #F5F5F5; 
    border-bottom: 1px solid #D0D0D0; 
}

.onboarding_action {
    display: flex;
    gap: 20px;
}

.onboarding-actions-section-title {
    font-weight: 800;
    font-size: var(--font-size-24);
    padding: 20px;
    padding-top: 0px;
}

.onboarding-action-icon {
    width: 64px;
    height: 64px;
}

.onboarding-action-subcontainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.onboarding-action-title {
    font-weight: 600;
    font-size: var(--font-size-20);
    color: #1A1A1A;
    width: 100%;
}

.task-item-coming-soon {
    display: flex;
    gap: 20px;
    background-color: #F5F5F5;
    padding: 10px;
    border-radius: 8px;
    justify-content: space-between;
}

.onboarding-action-subtitle {
    font-weight: 600;
    font-size: var(--font-size-16);
    color: #5B5B5B;
}

.onboarding-action-sub-subtitle {
    font-weight: 600;
    font-size: var(--font-size-16);
    color: #8d8d8d;
}

.onboarding-coming-soon {
    white-space: nowrap;
    font-weight: 600;
    font-size: var(--font-size-14);
    color: #7413BD;
}

.onboarding_arrow {
    width: 24px;
    height: 24px;
}

.onboarding-skip-section {
    text-align: center;
}

.onboarding-skip-button {
    background-color: #8A4FFF;
    border-color: #8A4FFF;
}

.onboarding-arrow-container {
    display: flex;
}

.adjust-onboarding-soon-pos {
    margin-left: 0px;
}


@media only screen and (max-width: 940px) {
    .onboarding-container {
        gap: 30px;
        height: 100% !important; 
    }

    .onboarding-subContainer {
        display: flex;
        flex-direction: column;
        gap: 35px;
        width: 100%;
    }

    .onboardingHeadingContainer {
        max-width: 100%;
    }

    .onboardingSkipContainer {
        max-width: 100%;
    }

    .onboarding-tasks {
        gap: 20px;
    }

    .onboarding-actions-section {
        padding: 20px;
        border: 1px solid #D0D0D0;
        border-radius: 13px;
    }

    .onboarding-main-title {
        padding-top: 10px;
    }
}


@media only screen and (max-width: 640px) {
    .onboarding-action-container-coming-soon {
        flex-direction: column;
    }
}


@media only screen and (min-width: 505px) and (max-width: 640px) {
    .adjust-onboarding-soon-pos {
        margin-left: 80px;
    }
}


@media only screen and (max-width: 504px) {
    .onboarding-action-icon {
        height: 40px; 
        width: 40px; 
    }
    .onboarding-action-container {
        padding: 20px 10px;
    }
    .onboarding-action-container-coming-soon {
        padding: 20px 10px;
    }
    .adjust-onboarding-soon-pos {
        margin-left: 58px;
    }
}


@media only screen and (max-width: 492px) {

    .task-item-coming-soon {
        flex-direction: column !important;
        gap: 10px !important;
    }

    .onboarding-arrow-container {
        display: none !important;
    }
}


@media only screen and (max-width: 376px) {

    .task-item-title-coming-soon {
        font-size: 14px !important;
    }
}